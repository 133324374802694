/* eslint-disable no-undef */
import React from "react"
import StructureCafae from "@components/pageCasosdeExito/template/templatePageCafae"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Cafae [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/cafae-se/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="CAFAE-SE es capaz de manejar sus cientos de interacciones diarias por WhatsApp con Beex Conversations, plataforma omnicanal de Beex."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Cafae" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/cafae-se/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-cafae.jpg`}
      />
      <meta
        property="og:description"
        content="CAFAE-SE es capaz de manejar sus cientos de interacciones diarias por WhatsApp con Beex Conversations, plataforma omnicanal de Beex."
      />
    </Helmet>
    <StructureCafae location={location} />
  </div>
)

export default IndexPage
