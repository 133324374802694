import React from "react"
import FotoCafae from "@components/pageCasosdeExito/image/imgPhotoEmpresa/cafae/FotoCafae.png"
import FotoCafae2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/cafae/FotoCafae2.png"

//logo de empresas clientes
import Cafae from "@components/pageCasosdeExito/image/logoscustomer/Cafae.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import livechat from "@components/pageCasosdeExito/image/iconcards/livechat.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/cafae/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/cafae/img2.svg"

const text1 = (
  <span className="">
    CAFAE-SE es una institución privada sin fines de lucro con más de 47 años de
    creación que ofrece múltiples servicios a los trabajadores del sector
    educación en Perú, como créditos, servicios de salud, centros recreativos y
    cultural, donaciones por salud y seguros varios.
    <br /> <br />
    Gracias a Beex, CAFAE-SE es capaz de atender los cientos de interacciones
    diarias de forma automatizada. Ha logrado reducir el tiempo de respuesta de
    su operación digital en más del 70% gracias a sus chatbots, sobre todo en
    WhatsApp, a través del API oficial.
  </span>
)

const text2 = (
  <span>
    CAFAE-SE (Comité de Administración del Fondo de Asistencia y Estímulo de los
    Trabajadores del Sector Educación), es considerada como el CAFAE más exitoso
    de la administración pública en el Perú por los diferentes servicios que
    brinda en beneficio de los trabajadores del sector educación del Estado.
    <br /> <br />
    Johans Herencia, encargado de la parte digital del CAFAE-SE, ha sido el
    contacto principal para la implementación de Beex en la operación digital
    de la organización. Desde el contacto comercial, hasta la puesta en
    producción, ha estado involucrado con los diferentes equipos para lograr los
    resultados esperados.
    <br /> <br />
    Al ser una institución de gran alcance, CAFAE-SE era consiente que los
    canales tradicionales de contacto no eran suficiente para abastecer los
    cientos de interacciones diarias que tenía, tanto para su atención al
    cliente, campañas de marketing y procesos de ventas.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    Beex nos ha permitido organizar mejor nuestros equipos de contenido,
    atención al cliente y ventas. Actualmente, gracias a los chatbots
    implementados, somos capaces de cerrar más del 60% de interacciones sin
    necesidad de intervención de un agente.”
  </p>
)

const text4 = (
  <span>
    {" "}
    Es por ello que, desde el inicio, el área de Marketing reconoció el problema
    principal y buscó una solución que mejor vaya con sus objetivos. Y con ello
    llegó Beex, que, en principio, se acomodó al proceso comercial y de
    comunicación que manejaban en la organización, brindando pago por
    uso/consumo acorde a la cantidad de usuarios que usarían la plataforma y a
    la cantidad de conversaciones por su canal principal, WhatsApp.
    <br /> <br />
    Esto último fue muy importante, ya que nuestro equipo comercial 
    ayudó a dimensionar el uso que la operación digital de CAFAE-SE podría tener
    en cuanto a conversaciones y mensajes podrían consumir por WhatsApp. Eso les
    permitiría contar con un presupuesto aproximado asociado mensualmente, que
    era uno de los puntos principales para implementar el canal.
    <br /> <br />
    Luego, vino lo mejor, Beex se implementó con éxito y en tiempo record.
    Gracias a la sinergia formada por los equipos de ambas organizaciones, se
    logró obtener el API de WhatsApp Business en días y poder dar de alta el
    canal, con chatbots incluidos, en tan solo un par de semanas.
  </span>
)

const text5 = (
  <span>
    Como ya lo mencionamos, la solicitud y acceso por parte de CAFAE-SE para
    acceder al API de WhatsApp Business fue rápido. De hecho, algo que suma
    mucho a su experiencia como marca, fue que, al poco tiempo de implementar y
    usar el canal, lograron obtener el check verde que certificaba que era un
    canal oficial aprobado por WhatsApp. Todo esto, con ayuda del equipo de
    Customer Success.
    <br /> <br />
    ¿Y qué sumó el API de WhatsApp Business a la operación digital de CAFAE-SE?
    Como menciona Johans, antes de tener integrado el canal a la solución, no
    contaban con tipificaciones por cada caso de sus usuarios que los ayude a
    tomar mejores decisiones y, además, saber en qué aspectos debían mejorar sus
    procesos de atención al cliente.
    <br /> <br />
    Además, al tener gran cantidad de consultas, CAFAE-SE supo desde el
    principio que necesitaba de una solución que pueda automatizar su
    comunicación digital, no solo a nivel de atención al cliente para preguntas
    frecuentes, sino para marketing y ventas que los ayuden a prospectar mejor.
    <br /> <br />
    Con nuestra plataforma, han sido capaces de diseñar y poner en producción chatbots en
    sus tiempos estimados. Al inicio, con la asesoría del equipo de Customer
    Success, implementaron los primeros chatbots, pero luego fueron totalmente
    autónomos de madurarlos con el tiempo. Como bien menciona Johans, para él y
    su equipo fue entretenido configurar sus chatbots en la plataforma, porque
    necesitaban amoldarlos a sus diferentes modelos de negocios y porque la
    experiencia de usuario de la plataforma se los permitía.
  </span>
)
const text6 = (
  <span>
    “Nuestros procesos de contacto y comunicación por nuestros canales digitales
    se han agilizado con Beex. Aparte de lograr cerrar casos sin necesidad de
    la intervención de un agente, hemos logrado reducir el tiempo de respuesta
    hacia nuestros clientes en más del 70%.”
  </span>
)

const text7 = (
  <span>
    Al ser una migración desde canales tradicionales a una solución omnicanal en
    la nube, el equipo de Customer Success se comprometió a que el
    proceso sea lo más ágil posible.
    <br /> <br />
    La integración entre equipos fue ideal y se pudo lograr hitos desde el
    inicio, como el rápido acceso al API de WhatsApp, la implementación de
    chatbots y la adopción de las diferentes funcionalidades, como reportería,
    supervisión en tiempo real, gestión multiagente y más.
    <br /> <br />
    Esto, no ha sido algo que solo se ha dado en la implementación, sino durante
    todo el tiempo de servicios que hemos compartido. CAFAE-SE es capaz de
    contactar rápidamente a soporte de Beex por el canal de WhatsApp para
    resolver sus dudas al instante. De hecho, es algo que Johans aprecia:
  </span>
)
const text8 = (
  <span>
    “El soporte de Beex es genial. Siempre responden de inmediato a las
    consultas sobre el manejo de la plataforma. Realmente todo va muy bien, sin
    tener problemas con la solución y logrando que tengamos una estabilidad
    ideal para nuestros procesos de atención omnicanal.”
  </span>
)

const text9 = (
  <p>
    Estos son algunos procesos con los que hemos ayudado
    a CAFAE-SE en su operación de atención al cliente, marketing y ventas:
  </p>
)

const text10 = (
  <p>
    Han pasado más de 2 años desde que CAFAE-SE eligió a Beex para
    implementar su solución omnicanal en la nube. La sinergia entre
    equipos y entendimiento ha hecho que la experiencia sea fructífera para
    ambas partes.
  </p>
)

const data = {
  intro: {
    image: Cafae,
    link: "https://www.youtube.com/watch?v=bbKdLdK7zLc&ab_channel=Securitec",
    classlogo: "logo__clientes--casa",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoCafae,
    text4: text4,
    title5: "WhatsApp y chatbots omnicanales",
    text5: text5,
    text6: text6,
    title7: "Acompañamiento total",
    text7: text7,
    text8: text8,

    fototwo: FotoCafae2,
    text9: text9,
    text10: text10,

    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para sus operaciones de atención al cliente, marketing y ventas. Beex logra que sus clientes conversen con CAFAE-SE mediante la App más usada a nivel mundial. Contando con enrutamiento inteligente, asignación de casos y más. ",
    },
    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots por los canales de más interacción con sus clientes, como WhatsApp y Facebook Messenger. Diseño y creación por rangos horarios para compartir respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año. ",
    },
    {
      title: "Omnicanalidad",
      img: livechat,
      info:
        "Sumado a WhatsApp, CAFAE-SE ha integrado Facebook Messenger a nuestra plataforma, logrando tener conversaciones por estos canales digitales a través de un solo hilo de conversación omnicanal en una sola ventana de conversación. ",
    },
  ],
}

export default data
